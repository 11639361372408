<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title float-left">Mitarbeiter</div>
            <v-btn
              color="success"
              class="float-right"
              fab
              @click="openDialog()"
            >
              <i class="material-icons">add</i>
            </v-btn>
          </v-col>
          <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Suche"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headers"
              :items="items"
              sort-by="name"
              :items-per-page="25"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
              }"
              :search="search"
            >
              <template v-slot:item.address="{ item }"
                >{{ item.street }} {{ item.houseno }}, {{ item.zip }}
                {{ item.city }}</template
              >
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="$router.push('/employee/' + item.id)"
                        fab
                        small
                        color="primary"
                      >
                        <v-icon class="material-icons"
                          >mdi-information-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Mitarbeiterdetails anzeigen</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="confirmDeleteItem(item.id, item.name)"
                        color="error"
                        small
                        fab
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Mitarbeiter löschen</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.entrydate="{ item }">
                <div>{{ $formatDate(item.entrydate) }}</div>
              </template>
              <template v-slot:item.gender="{ item }">
                <div v-if="item.gender === 'w'">Weiblich</div>
                <div v-if="item.gender === 'm'">Männlich</div>
                <div v-if="item.gender === 'd'">Divers</div>
              </template>
              <template v-slot:item.exitdate="{ item }">
                <div>{{ $formatDate(item.exitdate) }}</div>
              </template>
              <template v-slot:item.role="{ item }">
                <span v-if="item.role === 'admin'">Admin</span>
                <span v-if="item.role === 'pl'">Projektleiter</span>
                <span v-if="item.role === 'worker'">Service-Techniker</span>
              </template>
              <template v-slot:item.active="{ item }">
                <v-icon
                  color="green darken-2"
                  v-if="item.active === '1' || item.active === 1"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <v-icon color="red" v-else>mdi-checkbox-blank-circle</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Neuen Mitarbeiter erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEmployee.name"
                      label="Name*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEmployee.employeenumber"
                      label="Mitarbeiternummer"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="newEmployee.position"
                      label="Position"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="newEmployee.location"
                      label="Standort"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-select
                      :items="genders"
                      v-model="newEmployee.gender"
                      required
                      :rules="[rules.required]"
                      label="Geschlecht*"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      type="email"
                      v-model="newEmployee.email"
                      label="E-Mail*"
                      required
                      :rules="[rules.email]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      type="phone"
                      v-model="newEmployee.phone"
                      label="Telefon"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8 md8>
                    <v-text-field
                      v-model="newEmployee.street"
                      label="Straße"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="newEmployee.houseno"
                      label="Hausnummer"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md3>
                    <v-text-field
                      type="number"
                      v-model="newEmployee.zip"
                      max="99999"
                      label="PLZ"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm7 md9>
                    <v-text-field
                      v-model="newEmployee.city"
                      label="Stadt"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="newEmployee.entrydate"
                          label="Eintrittsdatum*"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          required
                          :rules="[rules.required]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newEmployee.entrydate"
                        no-title
                        first-day-of-week="1"
                        scrollable
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="newEmployee.exitdate"
                          label="Vertragsende"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          persistent-hint
                          hint="Leer lassen bei unbefristetem Vertrag"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newEmployee.exitdate"
                        first-day-of-week="1"
                        no-title
                        scrollable
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="items"
                      item-value="id"
                      item-text="name"
                      v-model="newEmployee.supervisorid"
                      label="Vorgesetzter"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="items"
                      item-value="id"
                      item-text="name"
                      v-model="newEmployee.cosupervisorid"
                      label="Stellver. Vorgesetzter"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="roles"
                      v-model="newEmployee.role"
                      label="Rolle*"
                      required
                      :rules="[rules.required]"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEmployee.password"
                      label="Password*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      type="number"
                      v-model="newEmployee.workinghours"
                      label="Arbeitsstunden pro Wocche"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="workingweek"
                      v-model="newEmployee.workingweek"
                      label="Arbeitswoche"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewEmployee()"
              >Anlegen</v-btn
            >
            <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Mitarbeiter löschen</v-card-title>
          <v-card-text>
            Sind sie sicher, dass Sie die Mitarbeiter
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      menu: false,
      menu2: false,
      deleteName: "",
      deleteId: "",
      search: "",
      items: [],
      supervisors: [],
      newEmployee: {
        name: "",
        email: "",
        employeenumber: "",
        gender: "m",
        position: "",
        location: "",
        email: "",
        phone: "",
        street: "",
        houseno: "",
        zip: "",
        city: "",
        supervisorid: 0,
        cosupervisorid: 0,
        password: "",
        workinghours: 40,
        workingweek: 5,
        entrydate: "",
        exitdate: "",
        role: "",
        active: "1",
      },
      workingweek: [
        {
          text: "5-Tage-Woche",
          value: 5,
        },
        {
          text: "6-Tage-Woche",
          value: 6,
        },
      ],
      genders: [
        {
          text: "Männlich",
          value: "m",
        },
        {
          text: "Weiblich",
          value: "w",
        },
      ],
      roles: [
        {
          text: "Service-Techniker",
          value: "worker",
        },
        {
          text: "Projektleiter",
          value: "pl",
        },
        {
          text: "Admin",
          value: "admin",
        },
      ],
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Mitarbeiternummer",
          value: "employeenumber",
        },
        {
          text: "Position",
          value: "position",
        },
        {
          text: "Geschlecht",
          value: "gender",
        },
        {
          text: "E-Mail",
          sortable: false,
          value: "email",
        },
        {
          text: "Telefon",
          sortable: false,
          value: "phone",
        },
        {
          text: "Anschrift",
          sortable: false,
          value: "address",
        },
        {
          text: "Eintrittsdatum",
          value: "entrydate",
        },
        {
          text: "Vertragsende",
          value: "exitdate",
        },
        {
          text: "Role",
          value: "role",
        },
        {
          text: "Aktiv",
          value: "active",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        email: [
          (value) => !!value || "Erforderlich.",
          (v) => /.+@.+/.test(v) || "E-Mail ist nicht korrekt",
        ],
      },
    };
  },
  mounted() {
   this.getEmployees();
  },
  methods: {
    createNewEmployee() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let employee = JSON.parse(JSON.stringify(this.newEmployee));
      let formData = new FormData();
      formData.append("mode", "new");
      formData.append("json", JSON.stringify(employee));
      this.$http({
        method: "post",
        url: "editEmployee.php",
        data: formData,
      })
        /*  this.$http
        .post("user/adduser", employee) */
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.userExists
          ) {
            const msg = "Es besteht bereits ein Mitarbeiter mit dieser E-Mail.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            return;
          }
          if (response.status === 200 && response.data) {
            const msg = "Neue Mitarbeiter wurde erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            employee.id = response.data.id;
            this.items.push(employee);
            this.dialog = false;
            Object.keys(this.newEmployee).forEach((prop) => {
              this.newEmployee[prop] = "";
              this.newEmployee["workinghours"] = 40;
              this.newEmployee["workingweek"] = 5;
              this.newEmployee["active"] = "1";
              this.newEmployee["gender"] = "m";
            });
          } else {
            const msg =
              "Neue Mitarbeiter konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Neue Mitarbeiter konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    getEmployees() {
      /*  this.$http({
        method: "get",
        url: "user/users", //"getCustomers.php"
      }) */
      this.$http({
        method: "post",
        url: "getEmployees.php",
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (
            response.status === 200 &&
            response.data &&
            response.data.data
          ) {
            this.items = response.data.data;
          }
        })
        .catch(error =>  {
          console.log(error);
        });
    },
    openDialog() {
      if (!this.supervisors.length) {
        this.$http({
          method: "post",
          url: "getSupervisors.php",
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (response.data && response.data.success && response.data.data) {
              this.supervisors = response.data.data;
            }
            this.dialog = true;
          })
          .catch(function (error) {
            this.dialog = true;
            console.log(error);
          });
      } else {
        this.dialog = true;
      }
    },
    confirmDeleteItem(id, name) {
      this.deleteName = name;
      this.deleteId = id;
      this.confirm = true;
    },
    deleteItem() {
      /* this.$http
        .delete("user/deleteuser/" + this.deleteId) */
      let formData = new FormData();
      formData.append("id", this.deleteId);
      this.$http({
        method: "post",
        url: "deleteEmployee.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 203) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.status === 200) {
            this.items.forEach((a, i) => {
              if (a.id === this.deleteId) {
                this.items.splice(i, 1);
                this.deleteName = "";
                this.deleteId = "";
                this.confirm = false;
              }
            });
            const msg = "Der Mitarbeiter wurde erfolgreich gelöscht.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Der Mitarbeiter konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>